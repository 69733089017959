import React, { useCallback, useMemo } from 'react';
import { hideModal } from 'shared/foreground/actions/modals';
import { globalState } from 'shared/foreground/models';

import Button from '../Button';
import styles from './AppStoreQRsModal.module.css';
import Modal from './Modal';

const iosQrId = 'ios-store-qr';
const androidQrId = 'android-store-qr';

export default function AppStoreQRsModal() {
  const modalIdToShow = globalState(useCallback((state) => state.modal, []));
  const isIos = useMemo(() => modalIdToShow === iosQrId, [modalIdToShow]);

  const hide = useCallback(() => {
    if (isIos) {
      hideModal({ id: iosQrId }, { userInteraction: 'click' });
    } else {
      hideModal({ id: androidQrId }, { userInteraction: 'click' });
    }
  }, [isIos]);

  const mainContent = (
    <>
      <div className={styles.qrCodeWrapper}>
        {isIos ? (
          <img className={styles.qrCode} alt="chrome extension logo" src="/onboarding/appstore-qr.svg" />
        ) : (
          <img
            className={styles.qrCode}
            alt="chrome extension logo"
            src="/onboarding/googleplay-qr.svg"
          />
        )}
      </div>
    </>
  );

  const footerContent = (
    <>
      <Button onClick={hide} variant="primary">
        Done
      </Button>
    </>
  );

  return (
    <>
      <Modal id={iosQrId} footerContent={footerContent} mainContent={mainContent} title="App Store QR" />
      <Modal
        id={androidQrId}
        footerContent={footerContent}
        mainContent={mainContent}
        title="Google Play QR"
      />
    </>
  );
}
