import React from 'react';
import getUIFriendlyNameForDocumentLocation from 'shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import { DocumentLocation } from 'shared/types';

import { Dialog } from './Dialog';

export default function ChangeDocumentLocationsConfirmationDialog({
  documentLocationInTitle,
  isShown,
  onCancel,
  onConfirm,
  subtitle,
}: {
  documentLocationInTitle: DocumentLocation;
  isShown: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  subtitle: string;
}) {
  if (!isShown) {
    return null;
  }

  return (
    <div>
      <Dialog
        action={onConfirm}
        actionTitle="Move"
        cancelAction={onCancel}
        cancelTitle="Cancel"
        subtitle={subtitle}
        id="change-document-locations-confirmation"
        title={`Move documents to ${getUIFriendlyNameForDocumentLocation(documentLocationInTitle)}?`}
      />
    </div>
  );
}
